/**
 * Formatte un nombre
 * @param {number} number le nombre à formatter
 * @param {number} nbDecimals le nombre de décimal à afficher (2 par défaut)
 * @returns le nombre formatté
 */
export const formatNumber = (number, nbDecimals) => {
  if (isNaN(number)) return number;
  if (nbDecimals === undefined) nbDecimals = 2;

  if (!number) number = 0;
  return new Intl.NumberFormat().format(number.toFixed(nbDecimals));
};

export const numberStringRound = (number, nbFixed) => {
  if (isNaN(number)) return number;
  if (nbFixed == undefined) nbFixed = 2;
  return Math.round(number).toLocaleString("de-DE", {
    maximumFractionDigits: nbFixed,
  });
};
