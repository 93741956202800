<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 1.5C0 1.10218 0.158035 0.720644 0.43934 0.43934C0.720644 0.158035 1.10218 0 1.5 0H4.5C4.89782 0 5.27936 0.158035 5.56066 0.43934C5.84196 0.720644 6 1.10218 6 1.5V4.5C6 4.89782 5.84196 5.27936 5.56066 5.56066C5.27936 5.84196 4.89782 6 4.5 6H1.5C1.10218 6 0.720644 5.84196 0.43934 5.56066C0.158035 5.27936 0 4.89782 0 4.5V1.5ZM8 1.5C8 1.10218 8.15804 0.720644 8.43934 0.43934C8.72064 0.158035 9.10218 0 9.5 0H12.5C12.8978 0 13.2794 0.158035 13.5607 0.43934C13.842 0.720644 14 1.10218 14 1.5V4.5C14 4.89782 13.842 5.27936 13.5607 5.56066C13.2794 5.84196 12.8978 6 12.5 6H9.5C9.10218 6 8.72064 5.84196 8.43934 5.56066C8.15804 5.27936 8 4.89782 8 4.5V1.5ZM0 9.5C0 9.10218 0.158035 8.72064 0.43934 8.43934C0.720644 8.15804 1.10218 8 1.5 8H4.5C4.89782 8 5.27936 8.15804 5.56066 8.43934C5.84196 8.72064 6 9.10218 6 9.5V12.5C6 12.8978 5.84196 13.2794 5.56066 13.5607C5.27936 13.842 4.89782 14 4.5 14H1.5C1.10218 14 0.720644 13.842 0.43934 13.5607C0.158035 13.2794 0 12.8978 0 12.5V9.5ZM8 9.5C8 9.10218 8.15804 8.72064 8.43934 8.43934C8.72064 8.15804 9.10218 8 9.5 8H12.5C12.8978 8 13.2794 8.15804 13.5607 8.43934C13.842 8.72064 14 9.10218 14 9.5V12.5C14 12.8978 13.842 13.2794 13.5607 13.5607C13.2794 13.842 12.8978 14 12.5 14H9.5C9.10218 14 8.72064 13.842 8.43934 13.5607C8.15804 13.2794 8 12.8978 8 12.5V9.5Z"
    />
  </svg>
</template>

<script>
export default {
  name: "SWGroupsIcon",
};
</script>
