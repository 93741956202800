<template>
  <svg width="18" height="18" viewBox="0 0 150 150" fill="none">
    <path
      d="M 20 2.5 C 10.305 2.5 2.5 10.305 2.5 20 L 2.5 130 C 2.5 139.695 10.305 147.5 20 147.5 L 130 147.5 C 139.695 147.5 147.5 139.695 147.5 130 L 147.5 20 C 147.5 10.305 139.695 2.5 130 2.5 L 20 2.5 z M 30.392578 24.126953 C 34.174561 24.126953 37.21875 27.171142 37.21875 30.953125 L 37.21875 83.884766 C 37.21875 87.666748 34.174561 90.710937 30.392578 90.710938 C 26.610595 90.710938 23.566406 87.666748 23.566406 83.884766 L 23.566406 30.953125 C 23.566406 27.171142 26.610595 24.126953 30.392578 24.126953 z M 62.447266 24.126953 C 66.229248 24.126953 69.273438 27.171142 69.273438 30.953125 L 69.273438 83.884766 C 69.273438 87.666748 66.229248 90.710938 62.447266 90.710938 C 58.665283 90.710938 55.621094 87.666748 55.621094 83.884766 L 55.621094 30.953125 C 55.621094 27.171142 58.665283 24.126953 62.447266 24.126953 z "
    />
  </svg>
</template>

<script>
export default {
  name: "SWConnections",
};
</script>
