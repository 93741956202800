<template>
  <div class="d-flex flex-column">
    <b-nav vertical class="p-0">
      <b-nav-item
        :to="{ name: 'groups' }"
        :key="$route.fullPath"
        link-classes="d-flex align-items-center m-2 p-2"
        active-class="active"
        ><SwGroups class="sidebar-icon mr-2" /><span class="text-uppercase">{{
          $t("sidebar.groups")
        }}</span></b-nav-item
      >
      <div
        class="mx-2 mt-2 p-2 cursor-p has-submenu"
        :class="{ active: menu == 'equipments' }"
        @click="menuClick('equipments')"
      >
        <i class="fa fa-stethoscope sidebar-icon mr-2"></i>
        <span class="text-uppercase">{{ $t("sidebar.equipments") }}</span>
      </div>
      <div class="submenu-bg pb-2 mx-2 mb-2 mt-0" v-if="isOpened.equipments">
        <b-nav-item
          :to="{ name: 'familiesparams' }"
          link-classes="d-flex align-items-center ml-4"
          active-class="sub-link-active"
          >{{ $t("common.equipmentFamilies") }}</b-nav-item
        >
        <b-nav-item
          :to="{ name: 'modelsparams' }"
          link-classes="d-flex align-items-center ml-4"
          active-class="sub-link-active"
          >{{ $t("common.equipmentModels") }}</b-nav-item
        >
      </div>
      <div
        class="mx-2 mt-2 p-2 cursor-p has-submenu"
        :class="{ active: menu == 'material' }"
        @click="menuClick('material')"
      >
        <SWConnections class="sidebar-icon mr-2" />
        <span class="text-uppercase">
          {{ $t("sidebar.material") }}
        </span>
      </div>
      <div class="submenu-bg pb-2 mx-2 mb-2 mt-0" v-if="isOpened.material">
        <b-nav-item
          :to="{ name: 'connectionsparams' }"
          link-classes="d-flex align-items-center ml-4"
          active-class="sub-link-active"
        >
          {{ $t("common.connections") }}
        </b-nav-item>
        <b-nav-item
          :to="{ name: 'attributesparams' }"
          link-classes="d-flex align-items-center ml-4"
          active-class="sub-link-active"
        >
          {{ $t("common.attributes") }}
        </b-nav-item>
      </div>
      <div
        class="mx-2 mt-2 p-2 cursor-p has-submenu"
        :class="{ active: menu == 'engine' }"
        @click="menuClick('engine')"
      >
        <b-icon icon="cpu-fill" class="sidebar-icon mr-2" />
        <span class="text-uppercase">{{ $t("sidebar.engine") }}</span>
      </div>
      <div class="submenu-bg pb-2 mx-2 mb-2 mt-0" v-if="isOpened.engine">
        <b-nav-item
          :to="{ name: 'levelsparams' }"
          link-classes="d-flex align-items-center ml-4"
          active-class="sub-link-active"
          >{{ $t("common.levels") }}</b-nav-item
        >
        <b-nav-item
          :to="{ name: 'zonesparams' }"
          link-classes="d-flex align-items-center ml-4"
          active-class="sub-link-active"
          >{{ $t("common.zones") }}</b-nav-item
        >
        <b-nav-item
          :to="{ name: 'wallsparams' }"
          link-classes="d-flex align-items-center ml-4"
          active-class="sub-link-active"
          >{{ $t("common.walls") }}</b-nav-item
        >
        <b-nav-item
          :to="{ name: 'gatewaysparams' }"
          link-classes="d-flex align-items-center ml-4"
          active-class="sub-link-active"
          >{{ $t("common.gateways") }}</b-nav-item
        >
        <b-nav-item
          :to="{ name: 'sensorsparams' }"
          link-classes="d-flex align-items-center ml-4"
          active-class="sub-link-active"
          >{{ $t("common.sensors") }}</b-nav-item
        >
        <b-nav-item
          :to="{ name: 'paramssets' }"
          link-classes="d-flex align-items-center ml-4"
          active-class="sub-link-active"
          >{{ $t("common.parameterSets") }}</b-nav-item
        >
      </div>

      <b-nav-item
        v-if="isAdmin"
        :to="{ name: 'admin' }"
        link-classes="d-flex align-items-center m-2 p-2"
        active-class="active bg-color-primary"
        ><b-icon icon="person-lines-fill" class="sidebar-icon mr-2" /><span
          class="text-uppercase"
          >{{ $t("sidebar.administration") }}</span
        ></b-nav-item
      >
    </b-nav>
  </div>
</template>

<script>
import SwGroups from "../icons/sw-groups.vue";
import SWConnections from "../icons/sw-connections.vue";
import { isAdmin } from "@/utils/user.js";

export default {
  components: { SwGroups, SWConnections },
  computed: {
    isAdmin() {
      return isAdmin(this.$auth.user);
    },
  },
  data() {
    return {
      menu: this.getActualMenu(),
      isOpened: this.getResetedIsOpened(),
    };
  },
  watch: {
    $route() {
      this.menu = this.getActualMenu();
      this.checkOpened();
    },
  },
  methods: {
    menuClick: function (menu) {
      this.menu = menu;
      this.checkOpened(menu);
    },
    getActualMenu: function () {
      if (this.$route.path.startsWith("/engine")) {
        return "engine";
      } else if (this.$route.path.startsWith("/equipments")) {
        return "equipments";
      } else if (this.$route.path.startsWith("/material")) {
        return "material";
      }
      return null;
    },
    getResetedIsOpened() {
      return {
        engine: false,
        equipments: false,
        material: false,
      };
    },
    checkOpened: function (toCheck) {
      if (!toCheck) {
        toCheck = this.getActualMenu();
      }
      this.isOpened = this.getResetedIsOpened();
      this.isOpened[`${toCheck}`] = true;
    },
  },
};
</script>

<style scope lang="scss">
.sidebar-icon {
  fill: var(--sw-dark);
  width: 2rem;
  text-align: center;
}

.nav-link {
  color: var(--sw-dark);
  text-transform: uppercase;
}

.nav {
  .has-submenu {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .submenu-bg {
    background: #ededed;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .sub-link-active {
    color: #00998f; // var(--sw-selected-sidebar);
    font-weight: 500;
  }
}

.active {
  color: var(--sw-light);
  background-color: var(--sw-selected-sidebar);
  border-radius: 0.5rem;
  .sidebar-icon {
    fill: var(--sw-light);
  }
  &:hover {
    color: var(--sw-light);
  }
}
</style>
