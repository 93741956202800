<template>
  <div class="d-flex sidebar-top flex-column align-items-center py-2">
    <SwLogo />
    <div class="sidebar-top__title">Manager</div>
  </div>
</template>

<script>
import SwLogo from "../icons/sw-logo.vue";
export default { components: { SwLogo } };
</script>

<style scoped lang="scss">
.sidebar-top {
  background-color: var(--sw-secondary-green-dark);
  &__title {
    color: white;
  }
}
</style>
