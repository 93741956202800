import { getUsers } from "@/utils/admin";

const emptyUser = {
  email: null,
  firstname: null,
  lastname: null,
  subscription_date: null,
  status: -1,
  role: null,
};
const initialState = {
  users: [],
  isBusy: true,
  isCreatingNewUser: false,
};

const adminModule = {
  namespaced: true,
  state: initialState,
  actions: {
    addEmptyUser({ commit }) {
      commit("addEmptyUser");
    },
    getUsers({ commit }) {
      const success = function (response) {
        commit("getUsersSuccess", response.data);
      };
      const failure = function (error) {
        console.error(error);
        commit("getUsersFailure");
      };

      getUsers(success, failure);
    },
  },
  mutations: {
    addEmptyUser(state) {
      state.users.push(emptyUser);
      state.isCreatingNewUser = true;
    },
    getUSersSuccess(state, users) {
      state.users = users;
      state.isBusy = false;
    },
    getUsersFailure(state) {
      state.isBusy = false;
    },
  },
};

export default adminModule;
