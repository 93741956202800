import { login } from "@/utils/user";
import router from "@/router/index.js";

const loggedOutState = {
  firstname: null,
  lastname: null,
  role: null,
  token: null,
  status: "loggedOut", //loggedOut, loggingIn, loggedId, loggingError
};

const user = JSON.parse(localStorage.getItem("user"));
const initialState = user ? user : loggedOutState;

const userModule = {
  namespaced: true,
  state: initialState,
  getters: {
    userFullName: (state) => {
      return state.firstname + " " + state.lastname;
    },
  },
  actions: {
    login({ commit }, { email, password, element }) {
      commit("loginRequest");

      login(element, email, password)
        .then((user) => {
          commit("loginSuccess", user);
          router.push("/groups");
        })
        .catch((error) => {
          commit("loginFailure", error);
        });
    },
    logout({ commit }) {
      commit("logout");
    },
  },
  mutations: {
    loginRequest(state) {
      state.status = "loggingIn";
    },
    loginSuccess(state, user) {
      state.status = "loggedIn";
      state.firstname = user.firstname;
      state.lastname = user.lastname;
      state.role = user.role;
      state.token = user.token;
      localStorage.setItem("user", JSON.stringify(user));
    },
    loginFailure(state) {
      state.status = "loggingError";
    },
    logout(state) {
      state.user = loggedOutState;
      localStorage.removeItem("user");
    },
  },
  modules: {},
};

export default userModule;
