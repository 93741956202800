import * as moment from "moment";

/**
 * Formatte une date
 * @param {*} date date à formatter
 * @param {string} format format de la date (défaut : DD/MM/YYYY à hh:mm)
 * @returns la date formattée
 */
export const formatDate = (date, format, isMilliseconds) => {
  if (format === undefined) format = "DD/MM/YYYY à hh:mm";
  if (date) {
    if (isMilliseconds) {
      date = new Date(date);
    }
    return moment(date).format(format);
  }
};
