<template>
  <div class="d-flex">
    <SideBar class="sidebar" v-if="hasNavBar" />
    <div
      class="content d-flex flex-column flex-grow-1"
      :class="{ hasSidebar: hasNavBar }"
    >
      <router-view :key="$route.fullPath" />
    </div>
  </div>
</template>

<script>
// Fontawesome
import SideBar from "./components/sidebar/SideBar.vue";
export default {
  name: "App",
  computed: {
    hasNavBar() {
      return this.$route.meta && this.$route.meta.noNavBar === true
        ? false
        : true;
    },
  },
  components: {
    SideBar,
  },
};
</script>

<style scope lang="scss">
.sidebar {
  height: 100vh;
  width: 16rem;
}

.content {
  height: 100vh;
  &.hasSidebar {
    width: calc(100% - 16rem);
  }
}
</style>
