import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueI18n from "vue-i18n";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import axios from "axios";
import VueAxios from "vue-axios";
import VCalendar from "v-calendar";
import VueKonva from "vue-konva";
import { Auth0Plugin } from "./auth/auth0-plugin";

// Filters
import { formatDate } from "./filters/formatDate";
import { formatNumber, numberStringRound } from "./filters/formatNumber";

Vue.config.productionTip = false;

// Css
import "./App.scss";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

// Axios for API calls
Vue.use(VueAxios, axios);

// Konva
Vue.use(VueKonva);

// Filters configuration
Vue.filter("formatDate", formatDate);
Vue.filter("formatNumber", formatNumber);
Vue.filter("numberStringRound", numberStringRound);

// Translations
Vue.use(VueI18n);
import { FRENCH_TRANSLATIONS } from "./translations/fr";
import { ENGLISH_TRANSLATIONS } from "./translations/en";
const TRANSLATIONS = {
  fr: FRENCH_TRANSLATIONS,
  en: ENGLISH_TRANSLATIONS,
};
const i18n = new VueI18n({
  locale: "fr",
  fallbackLocale: "fr",
  messages: TRANSLATIONS,
});

// v-calendar (date picker)
// Use v-calendar & v-date-picker components
Vue.use(VCalendar);

// Install the authentication plugin
const domain = window.APP_CONFIG.AUTH0_DOMAIN;
const clientId = window.APP_CONFIG.AUTH0_CLIENTID;
const audience = window.APP_CONFIG.AUTH0_AUDIENCE;
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
