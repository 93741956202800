export const FRENCH_TRANSLATIONS = {
  language: "fr",

  common: {
    groups: "groupes",
    group: "groupe",
    sites: "sites",
    site: "site",
    record: "Enregistrement",
    records: "Enregistrements",
    buildings: "bâtiments",
    building: "bâtiment",
    levels: "niveaux",
    level: "niveau",
    blueprints: "plans",
    blueprint: "plan",
    gateways: "passerelles",
    sensors: "capteurs",
    total: "Total",
    error: "Erreur",
    address: "Adresse",
    surface: "Surface",
    users: "utilisateurs",
    zones: "zones",
    parameterSets: "jeux de paramètres",
    walls: "murs",
    equipmentModels: "Modèles",
    equipmentFamilies: "Familles",
    browse: "parcourir",
    name: "Nom",
    description: "Description",
    action: "action",
    view: "vue",
    selected: "sélectionnés",
    algo: "Algorithme",
    success: "Opération réussie",
    connections: "Connexions",
    material: "Matériel",
    materialType: "Type de Matériel",
    type: "Type",
    parameters: "Paramètres",
    statut: "Statut",
    device: "Appareil",
    tag: "Kit",
    connectionType: "Type de connexion",
    connection: "Connexion",
    attributes: "Attributions",
    project: "Projet",
  },

  errors: {
    creation: "La création a échouée.",
    update: "La mise à jour a échouée.",
    delete: "La suppression a échouée.",
    import: "L'import a échoué.",
    data: "La récupération des données a échouée.",
    fileSize: "Le fichier excède la taille maximum ({maxSize} bytes)",
    optim: "L'optimisation a échouée.",
    emptyData: "Aucune donnée disponible",
    engineCalculation: {
      call: "Erreur lors de l'appel du service moteur",
    },
  },

  login: {
    email: "Email",
    password: "Mot de passe",
    password2: "Confirmation du mot de passe",
    save: "Continuer",
    invalidCredentials: "Vos identifiants sont incorrects",
    passwordIndicators: {
      lengthMin: "Doit contenir au moins 8 caractères",
      formatLower: "Doit contenir au moins une minuscule",
      formatUpper: "Doit contenir au moins une majuscule",
      formatNumber: "Doit contenir au moins un chiffre",
      formatSpecial: "Doit contenir au moins un caractère spécial",
    },
    errorPasswordMatch: "Les mots de passes ne correspondent pas",
    welcome: "Bienvenue",
    askValidation:
      "Afin de finaliser la création de votre compte, veuillez saisir un mot de passe.",
  },

  sidebar: {
    groups: "groupes",
    engine: "moteur",
    administration: "administration",
    equipments: "équipement",
    material: "matériel",
  },

  navbar: {
    app_name: "SWManager",
    admin: "administration",
    users: "utilisateurs",
    engineParameters: "paramètres moteur",
    deployment: "déploiement",
    records: "enregistrements",
    params: "paramétrisation",
  },

  records: {
    delete: "Supprimer l'enregistrement",
    confirmDelete: "Êtes-vous sûre de vouloir supprimer l'enregistrement",
    buttonCreateNew: "nouveau",
    orderBy: "Trier par :",
    orderByEndDate: "Date de fin",
    orderByStartDate: "Date de début",
    start: "Début",
    end: "Fin",
    sensors: "Nb capteurs",
    signals: "Nb signaux",
    creator: "Créateur",
    orderBySensorNumber: "Nombre de capteur",
    orderByBlueprint: "Plan",
    orderByGroup: "Groupe",
    orderByDuration: "Durée",
    on: "à",
    new: {
      step1: "Date de programmation",
      start: "Début :",
      now: "maintenant",
      end: "Fin :",
      step2: "Capteurs et positionnements",
      step3: "Validation",
      name: "Nom",
      description: "Description",
    },
  },

  connections: {
    deleteConfirmMsg1: "Êtes-vous sûre de vouloir supprimer la connexion",
  },

  attributes: {
    confirmationRemoveApply1: "Êtes-vous sûre de vouloir désaffecter les",
    confirmationRemoveApply2: "éléments sélectionné ?",
    not_attributed: "Non attribué",
  },

  applyModal: {
    message1: "Veuillez sélectionner l'affectation pour les",
    message2: "éléments sélectionnés:",
    selectGroup: "Sélectionner le groupe",
    selectSite: "Sélectionner le site",
    selectBuilding: "Sélectionner le bâtiment",
    bulkUpdate: "Affectation Groupé",
    bulkClearUpdate: "Validation de désaffectation",
  },
  applyButtons: {
    apply: "Affecter",
    remove: "Désaffecter",
  },

  deployment: {
    title: "déploiement",
    name: "nom",
    action: "action",

    groups: {
      logo: "logo",
      siteNumber: "nombre de sites",
      errorOnCreation: "Erreur pendant la creation du groupe",
      errorOnDeletion: "Erreur pendant la suppression du groupe {name}",
      importLogo: "Importer un logo",
      deleteConfirmMsg1: "Êtes-vous sûre de vouloir supprimer le groupe",
      deleteConfirmMsg2:
        "Cela entraînera la suppression de tous les éléments qui dépendent du groupe.",
    },

    sites: {
      buildingNumber: "nombre de bâtiments",
      address: "Adresse",
      code: "CP",
      city: "Ville",
      deleteConfirmMsg1: "Êtes-vous sûre de vouloir supprimer le site",
      deleteConfirmMsg2:
        "Cela entraînera la suppression de tous les éléments qui dépendent du site.",
    },

    buildings: {
      surface: "Surface",
      levelNumber: "nombre de niveau",
      deleteConfirmMsg1: "Êtes-vous sûre de vouloir supprimer le bâtiment",
      deleteConfirmMsg2:
        "Cela entraînera la suppression de tous les éléments qui dépendent du bâtiment.",
    },

    levels: {
      surface: "Surface",
      plan: "Plan",
      type: "Type",
      deleteConfirmMsg1: "Êtes-vous sûre de vouloir supprimer le niveau",
      deleteConfirmMsg2:
        "Cela entraînera la suppression de tous les éléments qui dépendent du niveau.",
    },
  },

  gateways: {
    gatewaysDispositions: "dispositions des passerelles",
    gatewaysPositions: "positionnements des passerelles",
    positions: "Positionnements",
    gatewaysNumber: "nombre de passerelles",
    creationDate: "date de création",
    type: "type",
    gateway: "passerelle",
    numberDispositions:
      "Aucune disposition | Total : {nb} disposition de passerelle | Total : {nb} dispositions de passerelles",
    numberPositions:
      "Aucun positionnement | Total : {nb} positionnement de passerelle | Total : {nb} positionnements de passerelles",

    modal: {
      deleteDispoConfirmMsg1:
        "Êtes-vous sûre de vouloir supprimer la disposition",
      deleteDispoConfirmMsg2:
        "Cela entraînera la suppression de tous les éléments qui dépendent de cette disposition.",
      deletePositionConfirmMsg1:
        "Êtes-vous sûre de vouloir supprimer la position",
      deletePositionConfirmMsg2:
        "Cela entraînera la suppression de tous les éléments qui dépendent de cette position.",
      newGatewayDisposition: "nouvelle disposition de passerelles",
      importGatewayPositions: "Importer des positionnements de passerelles",
      editDirType: "Edition DIR",
      editparametersType: "Edition paramètres",
    },

    gain: "Gain",
    kind: "Type",
    sampling: "Échantillonage",
    dir: "Dir",
    typeGateways: "types de passerelles",
    goSaved: "Accéder aux enregistrements",
    successOptimDispo:
      "Le calcul d'optimisation de positionnement des passerelles est en cours, elles seront bientôt disponible dans la liste des dispositions.",
    optimDispoTitle: "Exécution algorithme d'optimisation",
    errorOptim:
      "Une optimisation est déjà en cours, veuillez réessayer plus tard.",
  },

  sensors: {
    sensor: "Capteur",
    typeSensors: "Types de capteurs",
    sensorsPositions: "Positionnements des capteurs",
    sensorsDispositions: "Dispositions des capteurs",
    numberDispositions:
      "Aucune disposition de capteur | Total : {nb} disposition de capteur | Total : {nb} dispositions de capteurs",
    numberPositions:
      "Aucun positionnement de capteur | Total : {nb} positionnement de capteur | Total : {nb} positionnements de capteurs",
    gain: "Gain",
    sensorsNumber: "Nombre de capteurs",
    nbSensors: "{nb} capteur | {nb} capteur | {nb} capteurs",
  },

  levels: {
    L0: "L0",
    n: "N",
    typeLevels: "types de niveaux",
  },

  equipmentModels: {
    deleteConfirmMsg1:
      "Êtes-vous sûre de vouloir supprimer le modèle d'équipement",
    coef: "Coef",
    durationMaintenance: "Durée de maintenance",
    durationMaintenanceYear: "Durée",
    states: {
      open_close: "Ouvert / Fermé",
      avaibility: "Disponible / Indisponible",
      full_empty: "Vide / Plein",
      inflation: "Gonflage",
      none: "Aucun",
    },
    behaviors: {
      touch: "Touché",
      touch_toggle: "Touché réversible",
      proximity: "Proximité",
      temperature: "Température",
      inflating: "Gonflage",
      contact: "Contact",
    },
    defaultStates: {
      close: "Fermé",
      open: "Ouvert",
      available: "Disponible",
      unavailable: "Indisponible",
      empty: "Vide",
      full: "Plein",
      inflating: "Gonflage",
      inflated: "Gonflé",
      deflated: "Dégonflé",
    },
  },

  equipmentFamilies: {
    equipmentFamily: "Famille",
    stateAvailable: "état",
    stateDefault: "défaut",
    stateBehavior: "comportement",
    stateBehaviorParameters_edition: "Editer les paramètres du comportement",
    deleteConfirmMsg1:
      "Êtes-vous sûre de vouloir supprimer le famille d'équipement",
  },

  zones: {
    typeZones: "types de zones",
    name: "nom",
    description: "Description",
    probability: "Probabilité",
    nbGateway: "Nb passerelles",
    threshold: "Seuil",
    color: "Couleur",
    mountable: "Montable",
    deleteConfirmMsg1: "Êtes-vous sûre de vouloir supprimer le type de zone",
  },

  walls: {
    typeWalls: "types de murs",
    name: "nom",
    description: "Description",
    thickness: "épaisseur",
    coef: "Coef {i}",
    deleteConfirmMsg1: "Êtes-vous sûre de vouloir supprimer le type de mur",
  },

  parameterSets: {
    inProgress: "En cours",
    date: "Date",
    user: "Créateur",
    import: "Import",
    optim: "Optimisation",
    level: "Niveau",
    dispoGateways: "Disposition de passerelles",
    records: "Enregistrements",
    configFile: "Fichier de configuration",
    importFile: "Importer le fichier de configuration au format JSON",
    validation: "Validation",
    errorFile: "Le fichier importé n'est pas correct",
    activated: "Utiliser comme favoris",
    errorActivated: "Le jeu de paramètre ne peut être défini comme favoris.",
    optimValidation:
      "Voulez-vous lancer l’algorithme de déterminiation des paramètres des franchissements pour les éléments sélectionnés ?",
  },

  plans: {
    import: "Importer un plan",
    structure: "Structure",
    plan: "Plan",
  },

  blueprint: {
    activated_version: "Version activée",
    model_calculation: "Calcul du modèle",
    status: {
      success: "Succès",
      fail: "Échoué",
      warning: "Avertissement",
    },
    logs: "Journaux",
    version: "version",
    import: "importer",
    gateways: "passerelles",
    sensors: "capteurs",
    rooms: "pièces",
  },

  administration: {
    usersHandling: "Gestion des utilisateurs",
    confirmDeleteUser:
      "Êtes-vous sûre de vouloir supprimer l'utilisateur '{name}' ?",
  },

  user: {
    active: "activé",
    inactive: "désactivé",
    pending: "en attente",
    email: "email",
    firstname: "prénom",
    lastname: "nom",
    subscription_date: "date d'inscription",
    status: "status",
    role: "role",
  },

  modal: {
    cancel: "annuler",
    delete: "supprimer",
    confirmDeletion: "Confirmation de suppression",
    deletionValidation: "Validation de suppression",
    importBlueprint: "Import d'un plan",
    linkGatewayTitle: "lier une passerelle",
    linkSensorTitle: "lier un capteur",
    importPositions: "Importer des positions",
    importDisposition: "Importer une disposition",
  },

  button: {
    new: "nouveau",
    import: "importer",
    create: "créer",
    edit: "éditer",
    optim: "optimisation",
    all: "tous",
    selected: "sélectionnés",
    execute: "exécuter",
  },
  file: {
    noJson: "Aucun fichier .json",
    noSvg: "Aucun fichier .svg",
  },
  datepicker: {
    close: "Fermer",
    calendar: "Calendrier",
    nav: "Navigation",
    noDateSelected: "Aucune date sélectionnée",
    help: "Utilisez les curseurs pour naviguer",
    currentMonth: "Mois courrant",
    nextMonth: "Mois suivant",
    previousMonth: "Mois précédent",
    nextYear: "Année suivante",
    previousYear: "Année précédente",
    reset: "Réinitialiser",
    today: "Aujourd'hui",
    selectToday: "Sélectionner aujourd'hui",
    nbMonths: "{nb} mois",
    nbDays: "{nb} j",
    nbHours: "{nb}h",
    nbMinutes: "{nb} min",
  },
  breadcrum: {
    group: "Groupe : {name}",
    site: "Site : {name}",
    building: "Bâtiment : {name}",
    level: "Niveau : {name}",
    dispoGateway: "Disposition de passerelles : {name}",
    saves: "Enregistrements",
  },
  start_slope_min: "Pente min démarrage",
  start_slope_max: "Pente max démarrage",
  end_slope_min: "Pente min arrêt",
  end_slope_max: "Pente max arrêt",
};
