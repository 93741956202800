import Vue from "vue";
import VueRouter from "vue-router";
// import LoginView from "../views/LoginView.vue";
import {
  authenticationGuard,
  authenticationAdminGuard,
} from "@/auth/authenticationGuard";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    redirect: () => {
      // redirection vers la page 'groups'
      return { name: "groups" };
    },
  },
  {
    path: "/validation",
    name: "validation",
    component: () =>
      import("../views/ValidationAccount/ValidationAccountView.vue"),
    meta: {
      noNavBar: true,
    },
    props: (route) => {
      return { email: route.query.email };
    },
  },
  {
    path: "/groups",
    beforeEnter: authenticationGuard,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/GroupsView.vue"),
    children: [
      {
        path: "",
        name: "groups",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("@/components/groups/GroupsGroups.vue"),
      },
      {
        path: "group/:groupid",
        name: "group",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("@/components/groups/GroupsSites.vue"),
      },
      {
        path: "group/:groupid/site/:siteid",
        name: "site",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("@/components/groups/GroupsBuildings.vue"),
      },
      {
        path: "group/:groupid/site/:siteid/building/:buildingid",
        name: "building",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("@/components/groups/GroupsLevels.vue"),
      },
      {
        path: "group/:groupid/site/:siteid/building/:buildingid/level/:levelid",
        name: "level",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import("@/components/groups/Blueprint/GroupsGateways.vue"),
        props: () => {
          return { isSensors: false };
        },
      },
      {
        path: "group/:groupid/site/:siteid/building/:buildingid/level/:levelid/disposition/:dispositionid",
        name: "disposition",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import("@/components/groups/Blueprint/GroupsGateways.vue"),
        props: () => {
          return { isSensors: true };
        },
      },
      // {
      //   path: "group/:groupid/site/:siteid/building/:buildingid/level/:levelid/disposition/:dispositionid/optimisation",
      //   name: "optimGateways",
      //   // route level code-splitting
      //   // this generates a separate chunk (about.[hash].js) for this route
      //   // which is lazy-loaded when the route is visited.
      //   component: () =>
      //     import("@/components/groups/Blueprint/GroupsGateways.vue"),
      //   props: () => {
      //     return { isSensors: true };
      //   },
      // },
      {
        path: "group/:groupid/site/:siteid/building/:buildingid/level/:levelid/disposition/:dispositionid/records",
        name: "records",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import("@/components/groups/Records/RecordsComponent.vue"),
      },
      {
        path: "group/:groupid/site/:siteid/building/:buildingid/level/:levelid/disposition/:dispositionid/records/new",
        name: "new_record",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import("@/components/groups/Records/GroupsRecordNew.vue"),
      },
      {
        path: "group/:groupid/site/:siteid/building/:buildingid/level/:levelid/disposition/:dispositionid/record/:recordid",
        name: "record",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("@/components/groups/Records/GroupsRecord.vue"),
      },
    ],
  },
  {
    path: "/equipments",
    name: "equipments",
    beforeEnter: authenticationAdminGuard,
    component: () => import("../views/EquipmentsParamsView.vue"),
    children: [
      {
        path: "families",
        name: "familiesparams",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import("@/components/equipments/EquipmentFamiliesParams.vue"),
      },
      {
        path: "models",
        name: "modelsparams",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import("@/components/equipments/EquipmentModelsParams.vue"),
      },
    ],
  },
  {
    path: "/materials",
    name: "materials",
    beforeEnter: authenticationAdminGuard,
    component: () => import("../views/MaterialsParamsView.vue"),
    children: [
      {
        path: "connections",
        name: "connectionsparams",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("@/components/materials/ConnectionsParams.vue"),
      },
      {
        path: "attributes",
        name: "attributesparams",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("@/components/materials/AttributesParams.vue"),
      },
    ],
  },
  {
    path: "/engine",
    name: "engine",
    beforeEnter: authenticationAdminGuard,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/EngineParamsView.vue"),
    children: [
      {
        path: "levels",
        name: "levelsparams",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("@/components/engine/LevelsParams.vue"),
      },
      {
        path: "zones",
        name: "zonesparams",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("@/components/engine/ZonesParams.vue"),
      },
      {
        path: "walls",
        name: "wallsparams",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("@/components/engine/WallsParams.vue"),
      },
      {
        path: "gateways",
        name: "gatewaysparams",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("@/components/engine/GatewaysParams.vue"),
      },
      {
        path: "sensors",
        name: "sensorsparams",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("@/components/engine/SensorsParams.vue"),
      },
      {
        path: "parameters",
        name: "paramssets",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("@/components/engine/ParamsSets.vue"),
      },
      {
        path: "parameters/import",
        name: "paramssets_import",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("@/components/engine/ParamsSetsImport.vue"),
      },
      {
        path: "parameters/optim",
        name: "paramssets_optim",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("@/components/engine/ParamsSetsOptim.vue"),
      },
    ],
  },
  {
    path: "/admin",
    name: "admin",
    beforeEnter: authenticationAdminGuard,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/AdminView.vue"),
  },
  {
    path: "/not-allowed",
    name: "notAllowed",
    component: () => import("@/components/NotAllowed.vue"),
    meta: {
      noNavBar: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("@/components/NotFound.vue"),
    meta: {
      noNavBar: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
