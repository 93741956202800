<template>
  <div class="d-flex flex-column shadow-sm bg-white border-right">
    <SidebarTop />
    <SidebarMenu class="d-flex flex-grow-1" />
    <SidebarFooter />
  </div>
</template>

<script>
import SidebarTop from "./SideBarTop.vue";
import SidebarMenu from "./SideBarMenu.vue";
import SidebarFooter from "./SideBarFooter.vue";
export default {
  name: "SideBar",
  components: { SidebarTop, SidebarMenu, SidebarFooter },
};
</script>

<style></style>
