<template>
  <div
    class="d-flex align-items-center sidebar-bottom py-3 px-2 justify-content-between"
  >
    <b-avatar
      variant="light"
      class="mr-2 border border-primary avatar"
      size="2.5rem"
      :text="avatar"
    >
    </b-avatar>

    <div class="d-flex flex-column flex-grow-1">
      <div>
        <span class="firstname">{{ firstname }} </span
        ><span class="lastname">{{ lastname }}</span>
      </div>
      <div class="text-uppercase role">{{ role }}</div>
    </div>
    <div>
      <b-icon icon="gear-fill" font-scale="1.25" class="mr-2" />
      <b-icon icon="power" font-scale="1.25" @click="logout()" />
    </div>
  </div>
</template>

<script>
import { getRole } from "@/utils/user.js";

export default {
  computed: {
    firstname() {
      const firstname = this.$auth.user.given_name;
      this.appendInitial(firstname, 0);
      return firstname;
    },
    lastname() {
      const family_name = this.$auth.user.family_name;
      this.appendInitial(family_name, 1);
      return family_name;
    },
    role() {
      return getRole(this.$auth.user);
    },
  },
  data() {
    return {
      initials: ["", ""],
      avatar: "",
    };
  },
  methods: {
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
    appendInitial(name, index) {
      let initials = "";
      if (!name) {
        return;
      }
      let names = name.split(/[-, ]/);
      names.forEach((n) => {
        initials += n.substring(0, 1);
      });
      this.initials[index] = initials;
      this.avatar = this.initials.join("");
    },
  },
};
</script>

<style scope lang="scss">
.sidebar-bottom {
  background-color: var(--sw-secondary-green-dark);
  color: var(--sw-selected-light);
  font-size: 0.8rem;

  svg {
    cursor: pointer;
  }
}

.firstname {
  font-weight: 300;
}

.lastname {
  color: var(--sw-light);
  font-weight: 400;
}

.role {
  font-weight: 200;
}

.avatar {
  color: var(--sw-primary);
  .b-avatar-text {
    color: var(--sw-primary);
    font-weight: 800;
  }
}
</style>
