import Vue from "vue";
import Vuex from "vuex";

import userModule from "./modules/user";
import groupsViewModule from "./modules/groupsView";
import adminModule from "./modules/admin.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user: userModule,
    groupsView: groupsViewModule,
    admin: adminModule,
  },
});
