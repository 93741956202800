// Ce fichier contient des fonctions permettant la communication
// au backend

import $ from "jquery";
import { getInstance } from "../auth/auth0-plugin";

export function downloadFile(data, name, type) {
  const url = URL.createObjectURL(
    new Blob([data], {
      type: type,
    })
  );
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", name);
  document.body.appendChild(link);
  link.click();
}

// import { store } from "../store";

export function loading(isLoading) {
  if (isLoading) {
    $("body:not(.loading)").addClass("loading");
  } else {
    $("body").removeClass("loading");
  }
}

/**
 * Requête GET
 * @param {object} axios librairie permettant d'exécuter des requête HTTP
 * @param {string} url url associé au GET
 * @param {function} successCallback fonction à exécuter en cas de succès
 * @param {function} errorCallback fonction à exécuter en cas d'échec
 */
export function getRequest(
  axios,
  url,
  successCallback,
  errorCallback,
  headers,
  extraDataAxios
) {
  axiosRequest(
    axios,
    "GET",
    url,
    null,
    successCallback,
    errorCallback,
    headers,
    extraDataAxios
  );
}

/**
 * Requête POST
 * @param {object} axios librairie permettant d'exécuter des requête HTTP
 * @param {string} url url associé au GET
 * @param {object} data données de la requête
 * @param {function} successCallback fonction à exécuter en cas de succès
 * @param {function} errorCallback fonction à exécuter en cas d'échec
 * @param {object} headers header de la requête
 */
export function postRequest(
  axios,
  url,
  data,
  successCallback,
  errorCallback,
  headers,
  extraDataAxios
) {
  axiosRequest(
    axios,
    "POST",
    url,
    data,
    successCallback,
    errorCallback,
    headers,
    extraDataAxios
  );
}

/**
 * Requête PUT
 * @param {object} axios librairie permettant d'exécuter des requête HTTP
 * @param {string} url url associé au GET
 * @param {object} data données de la requête
 * @param {function} successCallback fonction à exécuter en cas de succès
 * @param {function} errorCallback fonction à exécuter en cas d'échec
 * @param {object} headers header de la requête
 */
export function putRequest(
  axios,
  url,
  data,
  successCallback,
  errorCallback,
  headers,
  extraDataAxios
) {
  axiosRequest(
    axios,
    "PUT",
    url,
    data,
    successCallback,
    errorCallback,
    headers,
    extraDataAxios
  );
}

/**
 * Requête DELETE
 * @param {object} axios librairie permettant d'exécuter des requête HTTP
 * @param {string} url url associé au GET
 * @param {object} data données de la requête
 * @param {function} successCallback fonction à exécuter en cas de succès
 * @param {function} errorCallback fonction à exécuter en cas d'échec
 * @param {object} headers header de la requête
 */
export function deleteRequest(
  axios,
  url,
  data,
  successCallback,
  errorCallback,
  headers,
  extraDataAxios
) {
  axiosRequest(
    axios,
    "DELETE",
    url,
    data,
    successCallback,
    errorCallback,
    headers,
    extraDataAxios
  );
}

/**
 * Lancement de la requête
 */
async function axiosRequest(
  axios,
  method,
  url,
  data,
  successCallback,
  errorCallback,
  headers,
  extraDataAxios
) {
  if (!headers) headers = {};
  if (data == null) data = {};
  if (!extraDataAxios) extraDataAxios = {};
  loading(true);

  try {
    const authService = getInstance();
    const accessToken = await authService.getTokenSilently();
    if (accessToken) {
      headers["Authorization"] = "Bearer " + accessToken;
    }
  } catch (error) {
    // error thrown
  }

  // lancement de la requête
  axios({
    method: method,
    url: url,
    headers: headers,
    data: data,
    ...extraDataAxios,
  })
    .then((response) => {
      loading(false);
      // la requête a aboutit avec succès
      if (successCallback) {
        successCallback(response);
      }
    })
    .catch(function (error) {
      loading(false);
      // une erreur s'est produite
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
