const initialState = {
  step: "groups", // groups, sites, buildings, levels
  currentGroupId: null,
  currentGroup: null,
  currentSiteId: null,
  currentSite: null,
  currentBuilingId: null,
  currentBuilding: null,
};

// eslint-disable-next-line no-unused-vars
const groupsViewModule = {
  namespaced: true,
  state: initialState,
  actions: {
    goToStep({ commit }, { step, id }) {
      commit("changeStep", { step, id });
    },
    storeGroup({ commit }, group) {
      commit("storeGroup", group);
    },
    storeSite({ commit }, site) {
      commit("storeSite", site);
    },
    storeBuilding({ commit }, building) {
      commit("storeBuilding", building);
    },
  },
  mutations: {
    changeStep(state, { step, id }) {
      state.step = step;
      switch (step) {
        case "groups":
          state.currentGroupId = null;
          state.currentGroup = null;
          state.currentSiteId = null;
          state.currentSite = null;
          state.currentBuildingId = null;
          state.currentBuilding = null;
          break;
        case "group":
          state.currentGroupId = id;
          state.currentSiteId = null;
          state.currentSite = null;
          state.currentBuildingId = null;
          state.currentBuilding = null;
          break;
        case "site":
          state.currentSiteId = id;
          state.currentBuildingId = null;
          state.currentBuilding = null;
          break;
        case "building":
          state.currentBuildingId = id;
          break;

        default:
          break;
      }
    },
    storeGroup(state, group) {
      state.currentGroup = group;
    },
    storeSite(state, site) {
      state.currentSite = site;
    },
    storeBuilding(state, building) {
      state.currentBuilding = building;
    },
  },
  modules: {},
};

export default groupsViewModule;
